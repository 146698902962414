import styled from "styled-components";

export const TopInfoContainer = styled.div`
  background: #23acde;
  color: #FFF;
  text-align: center;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 21px;
`
