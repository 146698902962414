export const REFERRALS_ACTION_TYPES = {
    SET_TIME: 'referrals/SET_TIME',
    SET_TIME_START: 'referrals/SET_TIME_START',
    SET_TIME_SUCCESS: 'referrals/SET_TIME_SUCCESS',
    SET_TIME_FAILED: 'referrals/SET_TIME_FAILED',
    SET_TIME_LOADING: 'referrals/SET_TIME_LOADING',
    FETCH_REFERRALS_START: 'referrals/FETCH_REFERRALS_START',
    FETCH_REFERRALS_SUCCESS: 'referrals/FETCH_REFERRALS_SUCCESS',
    FETCH_REFERRALS_FAILED: 'referrals/FETCH_REFERRALS_FAILED',
    SET_SORT_TYPE: 'referrals/SET_SORT_TYPE',
    SET_SORT_MY: 'referrals/SET_SORT_MY',
    SET_HIDE_ZERO: 'referrals/SET_HIDE_ZERO',
}
