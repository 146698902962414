import {
    MadalBodyTitle,
    ModalBody,
    ModalBodyTitleText,
    ModalBodyWrapper,
    ModalButtonClose,
    ModalCopyAddressText,
    ModalCopyAddressWrapper,
    ModalInputNick,
    ModalLinkText,
    ModalLinkWrapper,
    ModalOverlay,
    ModalTitle,
    ModalTitleWrapper,
    ModalWrapper
} from "./index.style";
import {ReactComponent as CloseButton} from '../../../../ui/images/close-white.svg';
import {ReactComponent as CloseButtonWhite} from '../../../../ui/images/close.svg';
import {ReactComponent as CopyButton} from '../../../../ui/images/copy.svg';
import {ReactComponent as CopiedButton} from '../../../../ui/images/copied.svg';
import {useDispatch, useSelector} from "react-redux";
import {selectMethod, selectUserAddress} from "../../../../store/user/user-selector";
import {
    getChainIdFromName,
    getChainNameFromId,
} from "../../../../utils/wallets/utils";
import {useEffect, useRef, useState} from "react";
import {DepositButtonContainer, DepositButtonStyle} from "../../Buttons/DepositButton/index.style";
import {selectCurrentUser} from "../../../../store/providerAndSigner/user-selector";
import {useChainId} from "../../../../hooks/web3";
import {utils} from "web3";
import {ethers} from "ethers";
import {setAddress, setMethod} from "../../../../store/user/user-actions";
import {setCurrentUser} from "../../../../store/providerAndSigner/user-actions";
import {useNetwork, useSwitchNetwork} from "wagmi";
import {getEthersProvider, getEthersSigner} from "../../../../utils/wallets/connectWallet";
import {getRefContractForNick, getRefContractForNickRead} from "../../../../utils/ethereum/ethereumFunctions";
import RegisterModalTx from "../RegisterNickModal/index.component";
import {selectTheme} from "../../../../store/theme/theme-selector";

const ReferralLinkModal = ({ setModalOpen }) => {
    const [copied, setCopied] = useState(false);
    const [copiedNick, setCopiedNick] = useState(false);
    const chainId = useChainId();
    const dispatch = useDispatch();
    const [nick, setNick] =useState('');
    const {signer} = useSelector(selectCurrentUser);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [txHash, setTxHash] = useState('');
    const address = useSelector(selectUserAddress);
    const walletType = useSelector(selectMethod);
    const theme = useSelector(selectTheme);
    //const inviter = '0x0000000000000000000000000000000000000000';
    const [txModal, setTxModal] = useState(false);
    const [isNickAlreadyReg, setIsNickAlreadyReg] = useState(false);
    const refLink = `https://app.airdrop-hunter.site/ref/?${address}`;
    const ref = useRef(null);

    const closeButton = theme === 'light' ?
        <CloseButton onClick={() => setModalOpen(false)} /> :
        <CloseButtonWhite onClick={() => setModalOpen(false)} />;

    const getNick = async () => {
        const contract = await getRefContractForNickRead();

        const nickFromContract = await contract.NickGet(address);

        if (nickFromContract !== '') {
            setNick(nickFromContract);
            setIsNickAlreadyReg(true);
        }
    }

    useEffect(() => {
        getNick();
    }, [])

    const modalRef = useRef();

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalOpen(false);
        }
    };

    const textareaRef = useRef(null);
    const textareaRefNick = useRef(null);

    const handleCopyButtonClickNick = () => {
        if (textareaRefNick.current) {
            textareaRefNick.current.select();
            document.execCommand('copy');
            setCopiedNick(true);
            setTimeout(() => setCopiedNick(false), 2000);
        }
    };

    const handleCopyButtonClick = () => {
        if (textareaRef.current) {
            textareaRef.current.select();
            document.execCommand('copy');
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };

    const copy = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            if (refLink === link) {
                setCopied(true); // Устанавливаем состояние, что скопировали
                setTimeout(() => {
                    setCopied(false); // Через секунду возвращаем состояние обратно
                }, 2000);
            } else {
                setCopiedNick(true); // Устанавливаем состояние, что скопировали
                setTimeout(() => {
                    setCopiedNick(false); // Через секунду возвращаем состояние обратно
                }, 2000);
            }
        });
    }

    const handleChange = (event) => {
        setNick(event.target.value);
    }

    const chainWagmi = useNetwork().chain;

    const { switchNetworkAsync } = useSwitchNetwork({
        async onSuccess(data) {
            const chainIdPast = chainWagmi?.id;

            const provider = getEthersProvider({chainId: chainIdPast});
            const signer = await getEthersSigner({chainId: chainIdPast});
            const userAddress = await signer?.getAddress();

            dispatch(setAddress(userAddress));
            dispatch(setMethod('WalletConnect'))
            dispatch(setCurrentUser({provider, signer}));
        },
        onMutate(args) {
            console.log('Mutate', args)
        },
    });

    const changeChain = async (chainId) => {
        try {
            switch (walletType) {
                case ('Metamask'): {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId: utils.toHex(chainId)}],
                    });

                    await window.ethereum.enable();
                    const provider = window.ethereum;

                    const provider2 = new ethers.providers.Web3Provider(provider);
                    const signer2 = await provider2.getSigner();
                    const userAddress = await signer2.getAddress();

                    dispatch(setAddress(userAddress));
                    dispatch(setMethod('Metamask'));
                    dispatch(setCurrentUser({provider: provider2, signer: signer2}));


                    break;
                }

                case ('OKX Wallet'): {
                    await window.okxwallet.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{chainId: utils.toHex(chainId)}],
                    });

                    await window.okxwallet.enable();
                    const provider = window.okxwallet;

                    const provider2 = new ethers.providers.Web3Provider(provider);
                    const signer2 = await provider2.getSigner();
                    const userAddress = await signer2.getAddress();

                    dispatch(setAddress(userAddress));
                    dispatch(setMethod('OKX Wallet'));
                    dispatch(setCurrentUser({provider: provider2, signer: signer2}));

                    break;
                }

                case ('WalletConnect'): {
                    await switchNetworkAsync(chainId)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    const registerFunc = async () => {
        setTxModal(true);
        setError('');
        setTxHash('');

        try {
            const chainId = 137;

            const contract = getRefContractForNick(chainId, signer);

            const gasLimit = await contract.estimateGas.NickRegister(nick, {
                value: '10000000000000000000'
            });

            const transaction = await contract.NickRegister(nick, {
                gasLimit: gasLimit * 2,
                value: '10000000000000000000'
            });

            setTxHash(transaction.hash);

            setIsLoading(true);
            await transaction.wait();
            setIsLoading(false);

            setIsNickAlreadyReg(true);
        } catch (err) {
            setError(err?.reason || err?.data?.message || err?.message);
        }
    }

    const buttonText = () => {
        if (nick !== '') {
            if (getChainNameFromId(chainId) === 'matic') {
                return (
                    <DepositButtonStyle onClick={registerFunc}>
                        Register
                    </DepositButtonStyle>
                )
            } else {
                return(
                    <DepositButtonStyle onClick={() => changeChain(getChainIdFromName('matic'))}>
                        Switch Chain
                    </DepositButtonStyle>
                )
            }

        } else {
            return (
                <DepositButtonStyle onClick={() => ref.current.focus()}>
                    Insert Nick
                </DepositButtonStyle>
            )
        }
    }

    return(
        <>
            <ModalOverlay onClick={handleOutsideClick}>
                <ModalWrapper ref={modalRef} onClick={(e) => e.stopPropagation()}>
                    <ModalTitleWrapper>
                        <ModalTitle>
                            Referral Link
                        </ModalTitle>
                        <ModalButtonClose>
                            {closeButton}
                        </ModalButtonClose>
                    </ModalTitleWrapper>
                    <ModalBody>
                        <ModalBodyWrapper>
                            {/*<MadalBodyTitle>*/}
                            {/*    <ModalBodyTitleText>*/}
                            {/*        Your Inviter:*/}
                            {/*    </ModalBodyTitleText>*/}
                            {/*    <ModalInveterText>*/}
                            {/*        {compressAddress(inviter)}*/}
                            {/*    </ModalInveterText>*/}
                            {/*</MadalBodyTitle>*/}
                            <MadalBodyTitle>
                                <ModalBodyTitleText>
                                    For each referral you bring, you will receive % to your balance.
                                </ModalBodyTitleText>
                            </MadalBodyTitle>


                            <ModalLinkWrapper style={{ marginBottom: '20px' }}>
                                <ModalLinkText
                                    ref={textareaRef}
                                    value={refLink}
                                    readOnly
                                />
                                <ModalCopyAddressWrapper onClick={handleCopyButtonClick}>
                                    {copied ?
                                        <>
                                            <CopiedButton />
                                            <ModalCopyAddressText>
                                                Copied!
                                            </ModalCopyAddressText>
                                        </> :
                                        <>
                                            <CopyButton />
                                            <ModalCopyAddressText style={{ marginRight: '15px' }}>
                                                Copy
                                            </ModalCopyAddressText>
                                        </>
                                    }
                                </ModalCopyAddressWrapper>
                            </ModalLinkWrapper>
                                {/*{*/}
                                {/*    !isNickAlreadyReg ?*/}
                                {/*        <>*/}
                                {/*        <MadalBodyTitle>*/}
                                {/*            <ModalBodyTitleText>*/}
                                {/*                You can choose a unique referral link based on your login, nickname (cost 10 MATIC on Polygon)*/}
                                {/*            </ModalBodyTitleText>*/}
                                {/*        </MadalBodyTitle>*/}
                                {/*        <ModalLinkWrapper>*/}
                                {/*            <ModalInputNick*/}
                                {/*                type="text"*/}
                                {/*                className="i2"*/}
                                {/*                placeholder="Your Nick cost 10 MATIC"*/}
                                {/*                id="iref_nick"*/}
                                {/*                maxLength="30"*/}
                                {/*                value={nick}*/}
                                {/*                onChange={handleChange}*/}
                                {/*                ref={ref}*/}
                                {/*            />*/}

                                {/*            <DepositButtonContainer>*/}
                                {/*                {buttonText()}*/}
                                {/*            </DepositButtonContainer>*/}
                                {/*        </ModalLinkWrapper>*/}
                                {/*        </> :*/}
                                {/*        <>*/}
                                {/*        <MadalBodyTitle>*/}
                                {/*            <ModalBodyTitleText>*/}
                                {/*                Your personal referral link*/}
                                {/*            </ModalBodyTitleText>*/}
                                {/*        </MadalBodyTitle>*/}
                                {/*            <ModalLinkWrapper style={{ marginBottom: '20px' }}>*/}
                                {/*                <ModalLinkText*/}
                                {/*                    ref={textareaRefNick}*/}
                                {/*                    value={`https://app.airdrop-hunter.site/ref/?${nick}`}*/}
                                {/*                    readOnly*/}
                                {/*                />*/}
                                {/*                <ModalCopyAddressWrapper onClick={handleCopyButtonClickNick}>*/}
                                {/*                    {copiedNick ?*/}
                                {/*                        <>*/}
                                {/*                            <CopiedButton />*/}
                                {/*                            <ModalCopyAddressText>*/}
                                {/*                                Copied!*/}
                                {/*                            </ModalCopyAddressText>*/}
                                {/*                        </> :*/}
                                {/*                        <>*/}
                                {/*                            <CopyButton />*/}
                                {/*                            <ModalCopyAddressText style={{ marginRight: '15px' }}>*/}
                                {/*                                Copy*/}
                                {/*                            </ModalCopyAddressText>*/}
                                {/*                        </>*/}
                                {/*                    }*/}
                                {/*                </ModalCopyAddressWrapper>*/}
                                {/*            </ModalLinkWrapper>*/}
                                {/*        </>*/}
                                {/*}*/}
                        </ModalBodyWrapper>
                    </ModalBody>
                </ModalWrapper>
            </ModalOverlay>
            { txModal ?
                <RegisterModalTx
                    txHash={txHash}
                    chain={'matic'}
                    nick={nick}
                    isLoading={isLoading}
                    error={error}
                    setIsModalOpen={setTxModal}
                /> :
                <></>
            }
        </>
    )
}

export default ReferralLinkModal;
