import styled from "styled-components";
import {mobileBreakpoint} from "../../ui/components/Modals/ClaimModal/index.style";

export const MainContainer = styled.div`
  max-width: 1140px;
  width: 80%;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: ${mobileBreakpoint}) {
    width: 100%;
  }
`

export const TextWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 10px 30px rgba(69, 88, 190, 0.1);
  border-radius: 10px;
  padding: 36px 36px 36px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const TitleBlMinimize = styled.div`
  margin-top: 5px;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: #636775;
`
