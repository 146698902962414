import {TopInfoContainer} from "./index.style";

const TopInfo = () => {
    return(
        <TopInfoContainer>
            This project is alpha. DYOR
        </TopInfoContainer>
    )
}

export default TopInfo;
