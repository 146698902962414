import styled from "styled-components";

export const TopHeaderContainer = styled.div`
  padding: 20px;
  align-items: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
`

export const TopHeaderWrapper = styled.div`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center!important;
  display: flex!important;
  box-sizing: border-box;
`

export const TopHeaderLeftSideContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #8D91A2;
`

export const TopHeaderRightSideContainer = styled.div`
  align-items: flex-end!important;
  display: flex!important;
  box-sizing: border-box;
`

export const TopHeaderRightSideWrapper = styled.div`
  justify-content: flex-end;
  width: 100%;
  align-items: center!important;
  display: flex!important;
  box-sizing: border-box;
`
