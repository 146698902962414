import CenterTitle from "../../modules/CenterTitle/index.component";
import SortLineModule from "../../modules/SortLine/index.module";
import {MainContainer} from "./MainPage.style";
import {TitleBL} from "../../ui/components/Titles/Titles.style";
import ReferBlock from "../../modules/ReferBlock/index.module";

const MainPage = () => {
    return(
        <>
            <CenterTitle />
            <MainContainer>
                <TitleBL>
                    Referral Program Overview
                </TitleBL>
                <SortLineModule/>
                <ReferBlock />
            </MainContainer>
        </>
    )
}

export default MainPage;
